<script setup>
import { SwiperSlide } from 'swiper/vue';

const { data: fetchedProducts } = await useLazyApiFetch(
    '/api/v1/product-logos'
);

const products = computed(() => fetchedProducts.value?.data ?? []);

const filterProducts = computed(() => {
    return products.value.filter((product) => {
        return product.logo !== null;
    });
});
</script>

<template>
    <CommonSwiperWrapper v-if="filterProducts?.length > 0">
        <SwiperSlide
            v-for="(product, index) in filterProducts"
            :key="`${index}-${product.name}`"
            class="!h-auto select-none"
        >
            <div class="flex items-center justify-center h-full">
                <img
                    :src="
                        product.logo_slider
                            ? product.logo_slider.src
                            : product.logo?.src
                    "
                    :alt="product.name"
                    class="max-w-full max-h-[28px] xl:max-h-[32px] pointer-events-none"
                />
            </div>
        </SwiperSlide>
    </CommonSwiperWrapper>
</template>
