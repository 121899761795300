<script setup>
import { Swiper } from 'swiper/vue';
import { Navigation, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';

const { onSwiper, onSlideChange, breakpoints } = useSwiperOptions(
    {},
    {
        2000: {
            slidesPerView: 3,
            centeredSlides: true,
            spaceBetween: 24,
        },
        768: {
            slidesPerView: 3,
            centeredSlides: true,
            spaceBetween: 24,
        },
        576: {
            slidesPerView: 2,
            centeredSlides: true,
            spaceBetween: 24,
        },
        320: {
            slidesPerView: 1,
            centeredSlides: true,
            spaceBetween: 24,
        },
    }
);

const customModules = [Navigation, Autoplay];
</script>

<template>
    <div class="container mx-auto relative">
        <swiper
            :modules="customModules"
            :navigation="{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }"
            :breakpoints="breakpoints"
            :autoplay="{
                delay: 6000,
                disableOnInteraction: false,
            }"
            class="!static flex-shrink-0 flex-grow"
            @swiper="onSwiper"
            @transition-start="onSlideChange"
        >
            <slot />
            <div class="swiper-button-prev">
                <IconCaretLeft class="mr-px" />
            </div>
            <div class="swiper-button-next">
                <IconCaretRight class="ml-px" />
            </div>
        </swiper>
    </div>
</template>
