<script setup>
const { externalLinks, hasLinks } = storeToRefs(useCurrentPlatformStore());
</script>

<template>
    <div v-if="hasLinks" class="text-white my-6 flex gap-3">
        <a
            v-if="externalLinks.siteNoticeUrl"
            :href="externalLinks.siteNoticeUrl"
            target="_blank"
            class="opacity-50 hover:opacity-100"
        >
            {{ $t('global.site_notice') }}
        </a>
        <span v-if="externalLinks.privacyUrl">|</span>
        <a
            v-if="externalLinks.privacyUrl"
            :href="externalLinks.privacyUrl"
            target="_blank"
            class="opacity-50 hover:opacity-100"
        >
            {{ $t('global.privacy') }}
        </a>
        <span v-if="externalLinks.privacyUrl">|</span>
        <CommonTooltipLayer
            v-if="externalLinks.serviceEmail || externalLinks.phoneNumber"
            cta-class="opacity-50 hover:opacity-100"
            :cta-label="$t('global.qa.title')"
            theme="light"
        >
            <template #default="slotProps">
                <CommonPopupContent
                    :arrow="slotProps.placement"
                    :theme="slotProps.theme"
                >
                    <i18n-t keypath="global.qa.layer" tag="div" scope="global">
                        <template #mail>
                            <CommonButton
                                :href-to="`mailto:${externalLinks.serviceEmail}`"
                                link
                                class="underline font-normal"
                            >
                                {{ externalLinks.serviceEmail }}
                            </CommonButton>
                        </template>
                        <template #phone>
                            <CommonButton
                                :href-to="`tel:${externalLinks.phoneNumber}`"
                                link
                                class="underline font-normal"
                            >
                                {{ externalLinks.phoneNumber }}
                            </CommonButton>
                        </template>
                    </i18n-t>
                </CommonPopupContent>
            </template>
        </CommonTooltipLayer>
    </div>
</template>
