<script setup>
useHead({
    bodyAttrs: {
        class: 'font-sans antialiased bg-slate-100 text-slate-800',
        tagDuplicateStrategy: 'replace',
    },
});
</script>

<template>
    <div class="w-full relative">
        <div
            class="absolute z-0 inset-0 before:content-[''] before:absolute before:inset-0 before:bg-slate-800/50 pointer-events-none"
        >
            <img
                src="~/assets/images/login-background.jpg"
                alt="login background image"
                class="w-full h-full object-cover"
            />
        </div>

        <main
            class="relative z-10 flex justify-center flex-col items-center h-full min-h-screen"
        >
            <div class="py-6 w-80 text-center">
                <CommonCurrentPlatformLogo
                    class="max-w-80 max-h-40 w-full object-contain"
                />
            </div>
            <NuxtPage />
            <ClientOnly>
                <CommonExternalLinks />
                <div class="mt-12 xl:mt-16 w-full max-w-5xl mx-auto">
                    <CommonProductSlider class="h-[50px] overflow-hidden" />
                </div>
            </ClientOnly>
        </main>
    </div>
</template>
